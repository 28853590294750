<style lang="scss" scoped>
    .container /deep/ {
        .cell-container {
            .mint-cell-title {
                color: #606266;
                font-size: 14px;
            }
            .mint-cell-wrapper {
                padding-left: 0;
            }
        }
    }
</style>
<template>

    <div class="container">
        <mt-cell class="cell-container" :title="title" :value="name" is-link @click.native="handleClick"></mt-cell>
        <mt-actionsheet v-model="show" :actions="actions" @closeOnClickModal="handleCloseOnClickModal"></mt-actionsheet>
    </div>

</template>

<script type="text/ecmascript-6">
    import {Actionsheet, Cell} from 'mint-ui'

    export default {
        name: 'EtActionsheet',
        //定义模版数据
        data() {
            return {
                show: false,
                name: '',
                actions: []
            }
        },
        props: {
            title: {
                type: String,
                default: ''
            },
            value: {
                type: [String, Number],
                default: ''
            },
            options: {
                type: Array,
                default: () => []
            }
        },
        mounted() {
            this.setData();
        },
        //计算属性
        components: {
            MtActionsheet: Actionsheet,
            MtCell: Cell
        },
        //定义事件方法
        methods: {
            handleClick() {
                this.show = true;
            },
            handleItemClick(index) {
                this.$emit('input', this.options[index].value)
                this.name = this.options[index].name;
            },
            handleCloseOnClickModal() {
                this.show = false;
            },
            setData() {
                this.actions = this.options.map((item, i) => ( {
                    ...item,
                    method: this.handleItemClick.bind(this, i)
                }))
                this.name = this.actions[0].name;
            }
        },
        watch: {
            options() {
                this.setData()
            },
            value(n) {
                this.name = (this.actions.find(({value}) => value == n) || {}).name;
            }
        }
    }
</script>
