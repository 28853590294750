import http from '../common/js/http'
//订单列表
const orderListHttp = async (form, url) => {
    return await http(url, form, "POST");
};
// //订单状态数据
// const getOrderStatusCount = async (form) => {
//     return await http('/galaxyOrder/doGetOrderStatusCountByLoginUserType', form, "POST");
// };
//根据id获取订单状态数量
const getOrderStatusCount = async (form, type) => {
    let url = type === 1 ? 'GetOrderStatusCountNotUseCar' : 'getOrderStatusCountByOperatorAndSupplierId';
    return await http(`/galaxyOrder/${url}`, form, "POST", false);
}
//客服信息列表
const OrderRemarkList = async (form) => {
    return await http('/galaxyOrder/OrderRemarkList', form, "POST");
};
//添加客服信息
const addOrderRemark = async (form) => {
    return await http('/galaxyOrder/addOrderRemark', form, "POST");
};
//分配司导
const distributionGuider = async (form) => {
    return await http('/galaxyOrder/distributionGuider', form, "POST");
};
//分配供应商
const distSupplier = async (form) => {
    return await http('/galaxyOrder/distributionGuider', form, "POST");
};
//订单变更
const orderChangeHttp = async (form) => {
    return await http('/galaxyOrder/modifyOrder', form, "POST");
};
//撤销订单变更
const withdrawOrderModification = async (form) => {
    return await http('/galaxyOrder/withdrawOrderModification', form, "POST");
};
//客服撤回待供应商确认的订单取消操作
const withdrawOrderCancellation = async (form) => {
    return await http('/galaxyOrder/withdrawOrderCancellation', form, "POST");
};
//客服拒绝订单取消申请
const rejectCancelApplication = async (form) => {
    return await http('/galaxyOrder/rejectCancelApplication', form, "POST");
};
//供应商接受订单取消
const acceptOrderCancellation = async (form) => {
    return await http('/galaxyOrder/acceptOrderCancellation', form, "POST");
};
//供应商接受订单修改
const acceptOrderModification = async (form) => {
    return await http('/galaxyOrder/acceptOrderModification', form, "POST");
};
//客服处理订单申请或发起取消订单操作
const cancelOrder = async (form) => {
    return await http('/galaxyOrder/cancelOrder', form, "POST");
};
//获取供应商
const getSupplierInfoByOperatorId = async (form) => {
    return await http('/galaxySupplier/doGalaxyGetSupplierInfoByOperatorId', form, "POST", false);
}
// //分配供应商
const addSupplier = async (form) => {
    return await http('/galaxyOrder/addSupplier', form, "POST", false);
}
// //重新分配供应商
const reallocateSupplier = async (form) => {
    return await http('/galaxyOrder/reallocateSupplier', form, "POST", false);
}
//运营商拒绝订单
const operatorRefuse = async (form) => {
    return await http('/galaxyOrder/operatorRefuse', form, "POST", false);
}
//供应商确认订单
const supplierConfirm = async (form) => {
    return await http('/galaxyOrder/supplierConfirm', form, "POST", false);
}
//供应商拒绝订单
const supplierRefuse = async (form) => {
    return await http('/galaxyOrder/supplierRefuse', form, "POST", false);
}
//重发确认短信给出行人
const operatorShortMess = async (form) => {
    return await http('/galaxyOrder/resendMessageToTripUser', form, "POST", false);
}
//供应商修改司导价格
const supplierUpdatePrice = async (form) => {
    return await http('/galaxyOrder/updateOrderDriverPrice', form, "POST", false);
}
//取消司导分配
const cancelDistributeGuider = async (form) => {
    return await http('/galaxyOrder/cancelDistributeGuider', form, "POST", false);
}
//总部获取供应商
export const getAllSupplier = async (form) => {
    return await http('/galaxySupplier/doGalaxyGetSupplierList', form, "POST");
};
//订单状态变更为新订待分配(拉回总部)
const getRestoreAssignSupplier = async (form) => {
    return await http('/galaxyOrder/updateOrderStatusToAssignSupplier', form, "POST");
};
//获取订单日志
const getOrderAuditLogByOrderId = async (orderId) => {
    return await http(`/galaxyOrder/doGetOrderAuditLogByOrderId/${orderId}`);
};


export default {
    orderListHttp,
    getOrderStatusCount,
    OrderRemarkList,
    addOrderRemark,
    distributionGuider,
    distSupplier,
    orderChangeHttp,
    withdrawOrderCancellation,
    withdrawOrderModification,
    rejectCancelApplication,
    acceptOrderCancellation,
    acceptOrderModification,
    cancelOrder,
    getSupplierInfoByOperatorId,
    addSupplier,
    reallocateSupplier,
    operatorRefuse,
    supplierConfirm,
    supplierRefuse,
    operatorShortMess,
    supplierUpdatePrice,
    cancelDistributeGuider,
    getAllSupplier,
    getRestoreAssignSupplier,
    getOrderAuditLogByOrderId
}