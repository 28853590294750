//订单来源
const sourceTypeList = [
    {name: 'TDS端', value: 1},
    {name: '携程', value: 2},
    {name: '飞猪', value: 3},
    {name: '马蜂窝', value: 4},
    {name: '去哪儿', value: 5},
    {name: '线下', value: 6},
    {name: '巴士出行', value: 7},
    {name: '900出行同业版', value: 8},
    {name: '900出行游客版', value: 9},
    {name: '900专车邮轮中心', value: 10},
    {name: '客服下单', value: 11},
    {name: '携程对接', value: 12},
    {name: '尚游', value: 13},
    {name: '沈阳900出行同业', value: 14},
    {name: '飞猪对接', value: 15},
    {name: ' 烟台小程序下单', value: 16},
    {name: ' 即时预约', value: 17},
    {name: ' 携程包车接口对接', value: 18},
    {name: ' 同程用车接口对接', value: 19},
    {name: ' 扫码下单', value: 20}
];

//支付渠道
const payChannelList = [
    {
        name: '支付宝(科技公司)',
        subjectName: '上海久柏易游信息科技有限公司',
        value: 1,
        type: '支付宝'
    },
    {
        name: '支付宝(旅行社)',
        subjectName: '上海久柏易游国际旅行社有限公司',
        value: 2,
        type: '支付宝'
    },
    {
        name: '微信支付(科技公司)',
        subjectName: '上海久柏易游信息科技有限公司',
        value: 3,
        type: '微信支付'
    },
    {
        name: '微信支付(旅行社)',
        subjectName: '上海久柏易游国际旅行社有限公司',
        value: 4,
        type: '微信支付'
    }, {
        name: '对公账号(旅行社)',
        subjectName: '上海久柏易游国际旅行社有限公司',
        value: 5,
        type: '公对公转账'
    }, {
        name: '其它(科技公司)',
        subjectName: '上海久柏易游信息科技有限公司',
        value: 6,
        type: ''
    }, {
        name: '其它(旅行社)',
        subjectName: '上海久柏易游国际旅行社有限公司',
        value: 7,
        type: ''
    }, {
        name: '月结(科技公司)',
        subjectName: '上海久柏易游信息科技有限公司',
        value: 8,
        type: ''
    }, {
        name: '月结(旅行社)',
        subjectName: '上海久柏易游国际旅行社有限公司',
        value: 9,
        type: ''
    }, {
        name: '对公账号(科技公司)',
        subjectName: '上海久柏易游信息科技有限公司',
        value: 10,
        type: '公对公转账'
    }
];

//支付类型
const payTypeList = [
    {desc: '微信app支付', value: 1, name: '微信支付'},
    {desc: '微信公众号支付', value: 2, name: '微信支付'},
    {desc: '支付宝pc端支付', value: 3, name: '支付宝支付'},
    {desc: '支付宝手机支付', value: 4, name: '支付宝支付'},
    {desc: '微信扫码支付', value: 5, name: '微信支付'},
    {desc: '900出行小程序同业版(芜湖)', value: 6, name: '微信支付'},
    {desc: '900出行小程序游客版(芜湖)', value: 7, name: '微信支付'},
    {desc: '900专车小程序(邮轮中心)', value: 8, name: '微信支付'},
    {desc: '900出行小程序同业版(沈阳)', value: 9, name: '微信支付'},
    {desc: '900出行小程序游客版(沈阳)', value: 10, name: '微信支付'},
    {desc: '900好用车小程序(芜湖)', value: 11, name: '微信支付'},
    {desc: '支付宝直连支付', value: 12, name: '支付宝支付'},
    {desc: '公对公转账', value: 13, name: '公对公转账'},
    {desc: '其它', value: 14, name: '其它'},
    {desc: '月结', value: 15, name: '月结'}
];
export default {
    sourceTypeList,
    payChannelList,
    payTypeList
}
