/**
* Created by hansxing on 2019/3/21.
*/
<style lang="scss" scoped>
.refresh-top{
    position: fixed;
    bottom: 30px;
    right: 15px;
    .refresh-btn{
        height: 44px;
        width: 44px;
        img{
            width: 100%;
            height: 100%;
        }
    }

}
</style>
<template>

    <div class="refresh-top">
        <div class="refresh-btn" @click="handRefresh()">
            <img src="../../assets/refresh-comTop.png">
        </div>
    </div>

</template>

<script type="text/ecmascript-6">
    export default {
        //定义模版数据
        data() {
            let data
            return {
                data
            }
        },
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {

        },
        //定义事件方法
        methods: {
            handRefresh() {
                this.$emit("onRefresh");
            },
        },
        //监听模版变量
        watch: {}

    }
</script>